<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-5">
      <v-card-title class="justify-center">{{ textCard.title }}</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="validate"
        >
          <v-row>
            <!-- <pre>
             {{ partner }}
           </pre
            > -->
            <v-col cols="12" md="12" class="text-center">
              <v-hover v-slot:default="{}">
                <v-list-item-avatar class="mx-auto" color="grey" size="150">
                  <croppa
                    v-model="croppa"
                    ref="croppa"
                    :width="150"
                    :height="150"
                    :placeholder="$t('form_candidate_upload')"
                    :placeholder-font-size="12"
                    :initial-image="
                      partner.photo
                        ? `${apiUrl}/images/partner/${partner.photo}`
                        : `/avatar.png`
                    "
                  >
                    >

                    <!-- 
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-ease-in-ease-out orange darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-btn
                          text
                          outlined
                          color="white"
                          class
                          style="opacity: 1;"
                          fab
                          large
                          top
                          @click="$refs.croppa.chooseFile()"
                        >
                          <div class="text-center">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  fab
                                  class="mx-2"
                                  color="grey"
                                  v-on="on"
                                >
                                  <v-icon>mdi-camera-plus</v-icon>
                                </v-btn>
                              </template>
                              <span>Carregar foto</span>
                            </v-tooltip>
                          </div>
                        </v-btn>
                      </div>
                    </v-expand-transition> -->
                  </croppa>
                </v-list-item-avatar>
              </v-hover>
              <v-btn
                color="primary"
                style="margin-left: -8%; margin-top: 20%"
                icon
                small
                @click="croppa.chooseFile()"
                ><v-icon>mdi-camera-plus</v-icon></v-btn
              >
            </v-col>
            <v-col
              cols="12"
              md="12"
              style="margin-top: -5%; margin-bottom: -3%"
            >
              <v-radio-group
                row
                v-model="partner.userType"
                required
                :rules="requiredRules"
              >
                <v-col cols="12" md="6" style="margin-left: -3%">
                  <v-radio
                    :label="$t('partner_user_type_cmp')"
                    color="orange"
                    value="Company"
                  ></v-radio>
                </v-col>
                <v-col cols="12" md="6">
                  <v-radio
                    :label="$t('partner_user_type_ind')"
                    color="orange"
                    value="Individual"
                  ></v-radio>
                </v-col>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" class="mb-0">
              <v-text-field
                v-model="partner.name"
                :readonly="show"
                :label="$t('form_user_name')"
                dense
                prepend-inner-icon="mdi-account"
                outlined
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" style="margin-top: -4%">
              <v-text-field
                v-model.trim="partner.email"
                :readonly="show"
                label="Email"
                dense
                prepend-inner-icon="mdi-email"
                outlined
                required
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" style="margin-top: -4%">
              <v-text-field
                v-model.trim="partner.telephone"
                :readonly="show"
                :label="$t('form_user_phone')"
                dense
                prepend-inner-icon="mdi-phone"
                outlined
                required
                type="number"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-row
              class="mt-0 mb-2 mx-0"
              v-if="partner.userType === 'Individual'"
            >
              <v-col cols="12" md="6">
                <v-select
                  v-model="partner.relationship"
                  :items="[$t('Married'), $t('Single')]"
                  :label="$t('partner_Relationship')"
                  dense
                  outlined
                  prepend-inner-icon="mdi-account-multiple"
                  :rules="requiredRules"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="partner.paceBirth"
                  :items="place"
                  :label="$t('partner_Place_birth')"
                  dense
                  outlined
                  prepend-inner-icon="mdi-map-marker-multiple"
                  :rules="requiredRules"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="12" class="mt-0 pt-0">
                <v-text-field
                  v-model="partner.address"
                  :label="$t('partner_address')"
                  dense
                  prepend-inner-icon="mdi-map-marker-radius"
                  outlined
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="partner.identity"
                  :label="$t('partner_Identity')"
                  dense
                  prepend-inner-icon="mdi-account-box-outline"
                  outlined
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  :close-on-content-click="false"
                  :return-value.sync="partner.issue"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="partner.issue"
                      :label="$t('partner_Issue')"
                      prepend-inner-icon="event"
                      readonly
                      v-on="on"
                      outlined
                      dense
                      required
                      :rules="requiredRules"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="partner.issue" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startMenu.save(partner.issue)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="partner.nuit"
                  :label="$t('partner_Nuit')"
                  dense
                  prepend-inner-icon="mdi mdi-account-box"
                  outlined
                  required
                  :rules="requiredRules"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0 mb-2 mx-0" v-if="partner.userType === 'Company'">
              <v-col cols="12" md="12" class="mt-0 pt-0">
                <v-text-field
                  v-model="partner.companyName"
                  :label="$t('partner_user_type_cmp')"
                  dense
                  prepend-inner-icon="mdi-map-marker-radius"
                  outlined
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="mt-2 pt-0">
                <v-text-field
                  v-model="partner.address"
                  :label="$t('partner_address')"
                  dense
                  prepend-inner-icon="mdi-map-marker-radius"
                  outlined
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="partner.representative"
                  :label="$t('partner_representative')"
                  dense
                  prepend-inner-icon="mdi-account-box-outline"
                  outlined
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="partner.permit"
                  :label="$t('partner_permit')"
                  dense
                  prepend-inner-icon="mdi mdi-account-box"
                  outlined
                  required
                  :rules="requiredRules"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="partner.nuit"
                  :label="$t('partner_Nuit')"
                  dense
                  prepend-inner-icon="mdi mdi-account-box"
                  outlined
                  required
                  type="number"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="12" md="6" style="margin-top: -4%">
              <v-select
                v-model="partner.bank"
                :items="[
                  'BCI',
                  'Millennium Bim',
                  'ABSA',
                  'Standard Bank',
                  'Access Bank Moçambique',
                  'Banco Único',
                  'Moza Banco',
                  'Banco +',
                  'BNI',
                  'UBA',
                ]"
                :label="$t('bank')"
                dense
                outlined
                prepend-inner-icon="mdi-bank"
                :rules="requiredRules"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" style="margin-top: -4%">
              <v-text-field
                v-model.trim="partner.account_number"
                :readonly="show"
                :label="$t('account_number')"
                dense
                prepend-inner-icon="mdi-briefcase-account"
                outlined
                required
                type="number"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" style="margin-top: -4%">
              <v-text-field
                v-model.trim="partner.nib"
                :readonly="show"
                :label="$t('nib')"
                dense
                prepend-inner-icon="mdi-briefcase-account"
                outlined
                required
                type="number"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="partner.areasExpertise"
                :items="getTrainingAreas($i18n.locale)"
                item-text="text"
                item-value="key"
                :label="$t('ApplicabilityAreas')"
                dense
                chips
                small-chips
                outlined
                multiple
                :rules="requiredRules"
              >
                <!-- <template v-slot:prepend-item>
                  <!- <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        v-if="partner.areasExpertise"
                        :color="partner.areasExpertise.length > 0 ? 'primary' : ''"
                      >
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("SelectAll") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> --
                  <v-divider class="mt-2"></v-divider>
                </template> -->
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="partner.office"
                :readonly="show"
                :label="$t('partner_hist')"
                auto-grow
                outlined
                rows="1"
                row-height="15"
                required
                :rules="requiredRules"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="12">
              <v-btn v-if="showP" @click="changePassword()" color="primary"
                >{{ $t("partner_reset_pswd") }}
              </v-btn>
            </v-col>
            <v-col v-if="!showP" cols="12" md="6">
              <v-text-field
                v-model.trim="password"
                :label="$t('partner_password')"
                type="password"
                dense
                prepend-inner-icon="mdi-lock"
                outlined
              ></v-text-field>
            </v-col>

            <v-col v-if="!showP" cols="12" md="6">
              <v-text-field
                v-model.trim="repeatPassword"
                :label="$t('form_user_pass_check')"
                type="password"
                dense
                prepend-inner-icon="mdi-lock"
                outlined
                :rules="repeatPasswordRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn color="primary" text class="mx-2" @click="close()">{{
              show ? $t("close") : $t("cancel")
            }}</v-btn>
            <v-btn
              v-if="!show"
              :disabled="!valid"
              type="submit"
              color="primary"
              >{{ $t("save") }}</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="closeErrorDialog"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { UPDATE_PARTNER_MUTATION } from "./../graphql/Mutation";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import { /*GET_PARTNER,*/ UPDATED_PARTNER } from "../graphql/Query";
import moment from "moment";
export default {
  name: "EditPartnerDialog",
  components: { ErrorDialog, ProgressDialog, SuccessDialog },
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
    partner: Object,
    show: Boolean,
  },
  data: () => ({
    apiUrl: API_URL,
    croppa: {},
    repeatPassword: "",
    roles: [],
    // partner: [],
    endMenu: false,
    startMenu: false,
    userType: null,
    valid: true,
    password: null,
    showP: true,
  }),
  apollo: {
    $subscribe: {
      message: {
        query: UPDATED_PARTNER,
        result({ data }) {
          this.partner = data.partner_sub;
        },
      },
    },
  },
  watch: {
    partner() {
      this.partner.office = this.partner.user.office;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTrainingAreas: "library/trainingAreas",
      getCountiesMZ: "library/countiesMZ",
    }),
    textCard() {
      return this.partner.id
        ? { title: this.$t("partner_edit") }
        : { title: this.$t("partner_cad") };
    },
    repeatPasswordRules() {
      return [
        (v) => v === this.password || this.$t("partner_password_confirm_err"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("required_field"),
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    requiredRules() {
      return [(v) => !!v || this.$t("required_field")];
    },

    place() {
      return this.getCountiesMZ.map((element) => {
        return element.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      });
    },
  },
  mounted() {
    delete this.partner.user;
  },
  created() {
    this.partner.office = this.partner.user.office;
  },
  methods: {
    ...mapActions({
      setCurrentUserState: "auth/setUser",
      setUserState: "user/setUser",
      updateUserState: "user/updateUser",
      setPartnerState: "partner/setPartner",
      updatePartnerState: "partner/updatePartner",
    }),
    closeErrorDialog(value) {
      this.showError = value;
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.handleSubmit();
      }
    },
    changePassword() {
      this.showP = !this.showP;
    },
    dateFormat() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    async close() {
      this.$emit("close");
    },
    async handleSubmit() {
      this.isLoading = true;

      this.partner = [this.partner].map((p) => {
        delete p.user;
        delete p.status;
        delete p.mail;
        return p;
      });

      this.partner = this.partner[0];
      if (this.password) {
        this.partner = { ...this.partner, password: this.password };
      }
      if (this.croppa.hasImage()) {
        console.log("newPhoto");
        this.partner.newPhoto = this.croppa.generateDataUrl("image/jpeg", 0.8);
      }
      this.$refs.form.validate();
      if (
        this.partner.name != "" ||
        this.partner.telephone != null ||
        this.partner.email != null ||
        this.partner.office != null
      ) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_PARTNER_MUTATION,
            variables: {
              UserInputPartner: Object.assign(
                {},
                { ...this.partner, role: undefined, entity: undefined }
              ),
            },
          });
          this.currentUser = data.updatePartner;
          this.updatePartnerState(data.updatePartner);
          this.setCurrentUserState(data.updatePartner.user);
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.success = this.$t("partner_updat_success");
          this.showSuccess = true;
          if (this.showSuccess) {
            this.close();
          }
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.removeSpace {
  margin-bottom: -20%;
}
.show-btns:not(.on-hover) {
  color: #f7a400 !important;
}
</style>
