<template>
  <v-container fill-height class="fill-height" fluid>
    <!-- <pre>
      {{ partner }}
    </pre> -->
    <v-row justify="center">
      <v-col cols="12" md="6">
        <profile-card @edit="editDialog = true" :partner="partner" />
      </v-col>
    </v-row>

    <edit-profile-dialog
    v-if="editDialog"
      :dialog="editDialog"
      :partner="partner"
      @close="editDialog = false"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_PARTNER } from "../graphql/Query";
import EditProfileDialog from "./../components/EditProfileDialog";
import ProfileCard from "./../components/ProfileCard.vue";
export default {
  name: "Profile",
  components: { EditProfileDialog, ProfileCard },
  data: () => ({
    editDialog: false,
    partner: [],
  }),
  apollo: {
    partner: {
      query: GET_PARTNER,
      fetchPolicy: "no-cache",
      variables() {
        return { email: this.currentUser.email };
      },
      result({ data }) {
        this.partner = data.partner;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  created() {
    // console.log("Parceiro", this.partner);
    if ( this.partner.nuit===null && this.partner.address===null) {
      this.editDialog = !this.editDialog;
    }
  },
};
</script>